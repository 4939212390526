import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    const step = document.querySelector('.contact-steps').dataset.value
    const stepArray = document.querySelectorAll('.strips')[0].children

    if (step == 'information'){
      stepArray[0].classList.add('current')
      stepArray[0].classList.remove('done')
      stepArray[1].classList.remove('current')
    } else if (step == 'requirements') {
      stepArray[0].classList.add('done')
      stepArray[1].classList.add('current')
      stepArray[1].classList.remove('done')
      stepArray[2].classList.remove('current')
    } else if(step == 'budget_and_time'){
      stepArray[0,1].classList.add('done')
      stepArray[2].classList.add('current')
      stepArray[2].classList.remove('done')
      stepArray[3].classList.remove('current')
    } else if (step == 'contact_info'){
      stepArray[0,1,2].classList.add('done')
      stepArray[3].classList.add('current')
      stepArray[3].classList.remove('done')
    } else if (step == 'finish') {
      stepArray[0,1,2,3].classList.add('done')
    }
  }
}
