

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    const showAllButton = document.querySelector('#showAllProjects');
    const ele = document.querySelector('#projectss')
    showAllButton.addEventListener('click', function () {
      const project = document.querySelector('.last')

      if (project.classList.contains('d-none')){
        project.classList.remove('d-none')
        showAllButton.textContent = "Read less";
      }
      else{
        project.classList.add('d-none')
        showAllButton.textContent = "Read more";
        document.documentElement.scrollTop = 0;
      }
    });
  }
}
