import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    $(document).ready(function() {
      var tabs = $('.nav-tabs a');
      var currentTab = 0;
      var prevTabBtn = $('#prev-tab-btn');
      var nextTabBtn = $('#next-tab-btn');

      function showTab(index) {
        tabs.eq(index).tab('show');
        currentTab = index;

        // Check if the currentTab is the first or last tab and update the disabled class
        if (currentTab === 0) {
          prevTabBtn.addClass('disable');
          nextTabBtn.removeClass('disable');
        } else if (currentTab === tabs.length - 1) {
          prevTabBtn.removeClass('disable');
          nextTabBtn.addClass('disable');
        } else {
          prevTabBtn.removeClass('disable');
          nextTabBtn.removeClass('disable');
        }
      }

      showTab(currentTab);

      $('#next-tab-btn').click(function(e) {
        e.preventDefault();
        if (currentTab < tabs.length - 1) {
          showTab(currentTab + 1);
        }
      });

      $('#prev-tab-btn').click(function(e) {
        e.preventDefault();
        if (currentTab > 0) {
          showTab(currentTab - 1);
        }
      });
    });

  }
}
