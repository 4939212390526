import { Controller } from "stimulus"
import moment from 'moment'
import 'daterangepicker'

export default class extends Controller {

  connect() {
    // Date Range Picker for filters_applied_at
    $('#filters_applied_at').daterangepicker({
      autoUpdateInput: false,
      locale: {
        format: 'YYYY-MM-DD',
        cancelLabel: 'Clear'
      }
    });

    $('#filters_applied_at').on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD'));
    });

    $('#filters_applied_at').on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
    });

    // Date Range Picker for job_end_time
    $('#job_end_time').daterangepicker({
      singleDatePicker: true,
      autoUpdateInput: false,
      timePickerSeconds: true,
      timePicker24Hour: true,
      timePicker: true,
      startDate: moment().startOf('min'),
      minDate: new Date(),
      locale: {
        format: 'YYYY-MM-DD hh:mm:ss A'
      }
    });

    $('#job_end_time').on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('YYYY-MM-DD hh:mm:ss A'))
    });

    $('#job_end_time').on('cancel.daterangepicker', function(ev, picker) {
      var prevValue = this.value
      $(this).val(prevValue);
    });
  }
}
