import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    var fileInput = document.getElementById('job_application_resume');
    var fileNamePlaceholder = document.getElementById('file-name-placeholder');
    if (fileInput && fileNamePlaceholder) {
      fileInput.addEventListener('change', function () {

        var fileName = fileInput.files[0].name;

        fileNamePlaceholder.textContent = fileName;
      });
    }
  }
}
