import { Controller } from "stimulus"
import Cropper from "cropperjs";

export default class extends Controller {
  static targets = ['output', 'input', 'slider', 'blogImageOut', 'blogImageIn', 'modalOutput', 'imageModalOutput', 'outputClient', 'inputClient']

  static defaultImage = '';

  connect() {
    this.dt = new DataTransfer();
    this.defaultImage = this.outputTarget.src
    const that = this
    document.addEventListener('click', function(e){
      if(!document.querySelector('#iconModal').querySelector('.modal-content').contains(e.target) && document.querySelector('#iconModal').contains(e.target)){
        that.closeModal()
      }
      if(!document.querySelector('#imageModal').querySelector('.modal-content').contains(e.target) && document.querySelector('#imageModal').contains(e.target)){
        that.closeModal()
      }
    })
  }

  displayImagePreview(event) {
    event.preventDefault();

    var input = this.inputTarget
    var output = this.outputTarget
    let imageSrc
    var outputModal = this.modalOutputTarget
    var modal = document.querySelector('#iconModal')
    var ratio = 1

    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function () {
       output.src = reader.result
       imageSrc = reader.result
     }

     reader.readAsDataURL(input.files[0]);
      setTimeout(() => {
        this.openModal(imageSrc, modal, outputModal, ratio)
      }, 200)
    }
  }

  displayBlogImagePreview() {
    var input = this.blogImageInTarget
    var output = this.blogImageOutTarget
    let imageSrc
    var modal = document.querySelector('#imageModal')
    var outputModal = this.imageModalOutputTarget
    var ratio = 2;

    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function () {
       output.src = reader.result
       imageSrc = reader.result
     }

     reader.readAsDataURL(input.files[0]);
      setTimeout(() => {
        this.openModal(imageSrc, modal, outputModal, ratio)
      }, 200)
    }
  }


  openModal(imageSrc, modal, outputModal, ratio){
    var that = this

    this.modal = modal
    outputModal.src = imageSrc;

    $(this.modal).modal('show')
    setTimeout(() => {
      that.displayCropedPreview(outputModal, ratio )
    }, 200)
  }

  displayCropedPreview(outputModal, ratio) {

    let croppable = false;
    let x;

      x = new Cropper(outputModal, {
        aspectRatio: ratio,
        viewMode: 1,
        dragMode: 'move',
        cropBoxMovable: true,
        cropBoxResizable: false,
        toggleDragModeOnDblclick: false,
        ready: function () {
          croppable = true;
        },
        crop: function (event) {
          let data = event.detail;
          if (ratio == 1){
            $(`[id*="_crop_icon_x"]`).val(data.x);
            $(`[id*="_crop_icon_y"]`).val(data.y);
            $(`[id*="_crop_icon_w"]`).val(data.width);
            $(`[id*="_crop_icon_h"]`).val(data.height);
          } else{
            $(`[id*="_crop_image_x"]`).val(data.x);
            $(`[id*="_crop_image_y"]`).val(data.y);
            $(`[id*="_crop_image_w"]`).val(data.width);
            $(`[id*="_crop_image_h"]`).val(data.height);
          }
        }
    });
  }

  click = (e) => {
    e.preventDefault();

    const modalId = this.modal.id
    const doneBtn = e.target
    if(doneBtn.dataset.value == 'image'){
      var cropDataUrl = document.getElementById('crop-image')
                                .cropper
                                .getCroppedCanvas()
                                .toDataURL();
      $('.img-circle').attr('src', cropDataUrl);
    } else {
      var cropDataUrl = document.getElementById('crop-avatar')
                                .cropper
                                .getCroppedCanvas()
                                .toDataURL();
      $('.icon-circle').attr('src', cropDataUrl);
    }
    this.collapseModal(modalId);

  };

  emptyFields(modalId) {
    if (modalId == "imageModal"){
      $(`[id*="_crop_image_x"]`).val(' ');
      $(`[id*="_crop_image_y"]`).val(' ');
      $(`[id*="_crop_image_w"]`).val(' ');
      $(`[id*="_crop_image_h"]`).val(' ');
    } else {
      $(`[id*="_crop_icon_x"]`).val(' ');
      $(`[id*="_crop_icon_y"]`).val(' ');
      $(`[id*="_crop_icon_w"]`).val(' ');
      $(`[id*="_crop_icon_h"]`).val(' ');
    }
  }

  collapseModal(modalId) {
    if (modalId == "imageModal"){
    this.imageModalOutputTarget.cropper.destroy();
    } else {
      this.modalOutputTarget.cropper.destroy();
    }
  }

  closeModal() {
    const modalId = this.modal.id
    let dt = new DataTransfer();
    if (modalId == "imageModal"){
      this.blogImageInTarget.files = dt.files
      this.blogImageOutTarget.src = this.defaultImage;
    } else {
      this.inputTarget.files = dt.files
      this.outputTarget.src = this.defaultImage;
    }
    this.emptyFields(modalId)
    this.collapseModal(modalId)
  }

  displayClientImage(){
    var input = this.inputClientTarget
    var output = this.outputClientTarget

    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function () {
       output.src = reader.result }

      reader.readAsDataURL(input.files[0]);
    }
  }

  selectImage(event) {
    const uploadBtn = event.target
    if ( uploadBtn.dataset.value == 'blogImage'){
      this.blogImageInTarget.click();
    } else if (uploadBtn.dataset.value == 'clientImage'){
      this.inputClientTarget.click()
    } else {
      this.inputTarget.click();
    }
  }
}
