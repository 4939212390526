require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')

import jquery from 'jquery';
window.$ = window.jquery = jquery;

import 'bootstrap'
import '@fortawesome/fontawesome-free/css/all';

import 'custom/script'

import "@hotwired/turbo-rails"

import 'controllers'

import '../stylesheets/application'

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
