import { Controller } from "stimulus"

const MOB_SCREEN_WIDTH = 480;
export default class extends Controller {
  static get targets() { return ["wrapper"] }
  connect(){
    if (document.documentElement.clientWidth <= MOB_SCREEN_WIDTH) {
      document.querySelector('.wrapper').classList.add('active')
    } else {
      document.querySelector('.wrapper').classList.remove('active')
    }
    window.addEventListener('resize', function() {
      if (document.documentElement.clientWidth <= MOB_SCREEN_WIDTH) {
        document.querySelector('.wrapper').classList.add('active')
      } else {
        document.querySelector('.wrapper').classList.remove('active')
      }
    });
  }

  toggle(){
    this.wrapperTarget.classList.toggle('active')
  }
}
