import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const redBox = document.querySelector('.check-danger')
    const greenBox = document.querySelector('.check-success')
    const redText = document.querySelector('.danger-text')
    const greenText = document.querySelector('.success-text')
    const checkBox = document.querySelector('.check')
    redText.classList.add("opacity");

    checkBox.addEventListener('change', () => {
      if (greenBox.classList.contains("img-transition")){
        redBox.classList.add("img-transition");
        greenBox.classList.remove("img-transition");
        redText.classList.add("opacity");
        greenText.classList.remove("opacity");
      }
      else {
        redBox.classList.remove("img-transition");
        greenBox.classList.add("img-transition");
        redText.classList.remove("opacity");
        greenText.classList.add("opacity");
      }
    })
  }
}
