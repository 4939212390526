import { Controller } from "stimulus"
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static get targets() { return ['button', 'input'] }

  publishSwitch(event){
    event.preventDefault()
    const url = this.element.dataset.url
    const status = this.element.dataset.value === 'No' ? 'Publish' : 'Unpublish'
    this.request(url, status)
  }

  request(url, status) {
    if(confirm(`Are you sure you want to ${status}?`)){
      ajax({
        type: 'Patch',
        url: url,
        dataType: 'json',
        success: (data) => {
          const modelName = ['job', 'project', 'client_review', 'our_client', 'blog'].find(model => data[model]);
          this.updateIcon(data[modelName]);
        }
      });
    }
  }

  updateIcon(data){
    if(data.published){
      this.inputTarget.checked = true
      this.element.dataset.value = 'Yes'
      this.buttonTarget.classList.add('text-theme')
      this.buttonTarget.title = 'Unpublish'
    } else {
      this.inputTarget.checked = false
      this.element.dataset.value = 'No'
      this.buttonTarget.classList.remove('text-theme')
      this.buttonTarget.title = 'Publish'
    }
  }
}
