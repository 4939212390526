import { Controller } from "stimulus"
import Cropper from "cropperjs";

export default class extends Controller {
  static targets = ['output', 'input', 'modalOutput', 'cardModal']

  static defaultImage = '';

  connect(){
    this.dt = new DataTransfer();
    this.defaultImage = this.outputTarget.src
  }

  displayProjectImage(event) {
    event.preventDefault();

    var imageSrc
    var input = this.inputTarget
    var output = this.outputTarget
    var modal = this.cardModalTarget
    var outputModal = this.modalOutputTarget


    if (event.target.id == 'project_icon'){
      var ratio = 392 / 471;
      var image = 'card'
    } else {
      var ratio = 1200 / 1051;
      var image = 'banner'
    }

    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function () {
       output.src = reader.result
       imageSrc = reader.result
     }

     reader.readAsDataURL(input.files[0]);
     setTimeout(() => {
      this.openModal(imageSrc, modal, outputModal, ratio, image)
    }, 200)
    }
  }

  openModal(imageSrc, modal, outputModal, ratio, image){
    const that = this
    this.modal = modal
    outputModal.src = imageSrc;

    this.element.addEventListener('click', function(e){

      if(!that.element.querySelector('#cardImageModal').querySelector('.modal-content').contains(e.target)){
        that.closeModal()
      }
    })

    $(this.modal).modal('show')
    setTimeout(() => {
      that.displayCropedPreview(outputModal, ratio, image)
    }, 200)
  }

  displayCropedPreview(outputModal, ratio, image) {
    let croppable = false;
    let x;

      x = new Cropper(outputModal, {
        aspectRatio: ratio,
        viewMode: 1,
        dragMode: 'move',
        cropBoxMovable: true,
        cropBoxResizable: false,
        toggleDragModeOnDblclick: false,
        ready: function () {
          croppable = true;
        },

        crop: function (event) {
          let data = event.detail;

          if(image == 'card'){
            $(`[id*="_crop_icon_x"]`).val(data.x);
            $(`[id*="_crop_icon_y"]`).val(data.y);
            $(`[id*="_crop_icon_w"]`).val(data.width);
            $(`[id*="_crop_icon_h"]`).val(data.height);
          } else {
            $(`[id*="_crop_image_x"]`).val(data.x);
            $(`[id*="_crop_image_y"]`).val(data.y);
            $(`[id*="_crop_image_w"]`).val(data.width);
            $(`[id*="_crop_image_h"]`).val(data.height);
          }
        }
    });
  }

  click = (e) => {
    e.preventDefault();
    const outVal = this.outputTarget.dataset.value

      var cropDataUrl = this.modalOutputTarget.cropper
                                .getCroppedCanvas()
                                .toDataURL();

      if(outVal == 'projectCard'){
        $('.icon-circle').attr('src', cropDataUrl);
      } else {
        $('.img-circle').attr('src', cropDataUrl);
      }
    this.collapseModal();

  };

  emptyFields() {
      $(`[id*="_crop_image_x"]`).val(' ');
      $(`[id*="_crop_image_y"]`).val(' ');
      $(`[id*="_crop_image_w"]`).val(' ');
      $(`[id*="_crop_image_h"]`).val(' ');
      $(`[id*="_crop_image_x"]`).val(' ');
      $(`[id*="_crop_image_y"]`).val(' ');
      $(`[id*="_crop_image_w"]`).val(' ');
      $(`[id*="_crop_image_h"]`).val(' ');
  }

  collapseModal() {
    if (this.modalOutputTarget.cropper) {
      this.modalOutputTarget.cropper.destroy();
    }
  }


  closeModal() {
    let dt = new DataTransfer();
    this.inputTarget.files = dt.files
    this.outputTarget.src = this.defaultImage;

    this.emptyFields()
    this.collapseModal()
  }

  selectImage() {
   this.inputTarget.click();
  }
}
