import { Controller } from "stimulus";
import 'selectize'

export default class extends Controller {
  static targets = [
    'technologyStack',
    'keyFeatures',
    'rolePermissionSelect',
    'jobTitle',
    'jobLocation',
    'keyWords',
    'jobApplication'
  ]

  connect() {
    this.createSelect2Dropdown();
  }

  createSelect2Dropdown() {
    if (this.hasTechnologyStackTarget) { this.createMultipleSelectize('#project_technology_stack', 'Add technology stacks') }
    if (this.hasKeyFeaturesTarget) { this.createMultipleSelectize('#project_key_features', 'Add key features') }
    if (this.hasRolePermissionSelectTarget) { this.createMultipleSelectize('#' + this.rolePermissionSelectTarget.id, 'Select Role Permissions') }
    if (this.hasJobTitleTarget) { this.createSelectizeOnRequest('#' + this.jobTitleTarget.id, 'Enter Job Title') }
    if (this.hasJobLocationTarget) { this.createMultipleSelectize('#' + this.jobLocationTarget.id, 'Enter Job location') }
    if (this.hasKeyWordsTarget) { this.createMultipleSelectize('#blog_keywords', 'Enter keywords') }
    if (this.hasJobApplicationTarget) { this.createSelectizeOnRequest('#' + this.jobApplicationTarget.id, 'Read Status') }
  }

  createMultipleSelectize(id, placeholder) {
    $(id).selectize({
      delimiter: ',',
      persist: true,
      placeholder: placeholder,
      plugins: [ 'remove_button' ],
      create: function (input) {
        return {
          value: input,
          text: input,
        };
      }
    });
  }

  createSelectizeOnRequest(id, placeholder) {
    $(id).selectize({
      delimiter: ',',
      persist: false,
      placeholder: placeholder,
      plugins: [ 'remove_button' ],
      create: function(input, callback) {
        let data = {
          term: input
        };

        $.ajax({
          url: '/npanel/job_applications/fetch_job',
          async: false,
          type: 'POST',
          data: data,
          dataType: 'json',
          success: function(result) {
            if (result) {
              return callback({ value: result.slug, text: result.title });
            }
            else {
              return callback();
            }
          }
        });
      }
    });
  }
}
