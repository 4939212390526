import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    const firstService = document.querySelector('.service-image');
    const firstDescrip = document.querySelector('.description');
    const serviceButtons = document.querySelectorAll('.service_button');
    firstService.classList.add('show')
    firstDescrip.classList.add('show')
    serviceButtons[0].setAttribute('disabled', true)
    serviceButtons[0].children[0].removeAttribute('disabled')

    const that = this
    serviceButtons.forEach((button) => {
      button.addEventListener('click', function (event) {
        that.showHide(event.target)
      });
    });
  }

  showHide(eventBtn){
    $('.collapse').collapse('hide')

    document.querySelectorAll('.service_button').forEach((btn) => {
      btn.removeAttribute('disabled')
      btn.children[0].removeAttribute('disabled')
    })

    const targetButton = eventBtn.classList.contains('service_button') ? eventBtn  : eventBtn.parentElement

    setTimeout(function(){
      targetButton.setAttribute('disabled', true)
      targetButton.children[0].setAttribute('disabled', true)
    }, 500)
  }
}
