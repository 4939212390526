import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['invite', 'sendPassword', 'password']

  sendInvite(){
    this.inviteTarget.value = true
    this.sendPasswordTarget.value = this.passwordTarget.value
  }

  onlySave(){
    this.inviteTarget.value = false
  }
}
