import { Controller } from "stimulus"
import "jquery-ui/ui/widgets/sortable"

export default class extends Controller {

  connect() {
    $('#sortable').sortable({
      axis: 'y',
      handle: ".handle",
      update: (event, ui) => {
        const slug = ui.item.data('slug')
        const newPosition = $(ui.item).index() + 1;
        const page = document.querySelector(".current") ? document.querySelector(".current").innerHTML : 1
        $.ajax({
          type: "PATCH",
          url: "/npanel/projects/" + slug + "/update_position",
          data: { project: { position: newPosition, page: page } }
        });
      }
    })
  }
}
