import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const googleMetaTag = document.querySelector("meta[name='google-analytics-id']");
    const googleId = googleMetaTag ? googleMetaTag.content : null;

    if (googleId) {
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', googleId);
    }
  }
}
