import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() { return ["checkbox", "button","formContent", "icon"] }
  connect(){
    if(this.checkboxTarget.checked){
      this.iconTarget.classList.remove("fa-plus");
      this.iconTarget.classList.add("fa-minus");
      this.buttonTarget.classList.add("text-danger")
      this.buttonTarget.classList.remove("text-theme");
    }
  }

  toggle(){
    if(Array(...this.iconTarget.classList).includes('fa-minus')){
      this.buttonTarget.classList.remove("text-danger");
      this.buttonTarget.classList.add("text-theme");
      this.iconTarget.classList.add("fa-plus");
      this.iconTarget.classList.remove("fa-minus");
      this.checkboxTarget.checked = false
    }else{
      this.iconTarget.classList.remove("fa-plus");
      this.iconTarget.classList.add("fa-minus");
      this.buttonTarget.classList.add("text-danger")
      this.buttonTarget.classList.remove("text-theme");
      this.checkboxTarget.checked = true
    }
  }
}
